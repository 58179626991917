<template>
  <v-main class="auth-pages">
    <div
      class="header-auth position-relative ma-4 pb-16 pt-16 border-radius-xl"
      :class="'bg-gradient-default'"
    >
      <v-img
        src="@/assets/img/shapes/waves-white.svg"
        alt="pattern-lines"
        class="position-absolute opacity-6 start-0 top-0 w-100"
        v-if="this.$route.name == 'Pricing'"
      ></v-img>
      <v-container>
        <v-row class="d-flex mt-5">
          <v-col cols="12" md="6" class="mx-auto py-0">
            <h3 class="text-h3 font-weight-bold text-white text-center mb-2">
              {{ 'Privacy Policy'|trans }}
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="position-relative border-radius-xl min-vh-100">
      <v-container class="py-0">
        <v-row>
          <v-col lg="12" md="12" class="d-flex flex-column mx-lg-0 mx-auto">
            <v-card class="card-shadow border-radius-xl">
              <div class="px-6 pt-6 pb-4 text-center">
                <v-list
                  class="py-6 text-lg-start text-center"
                  color="transparent"
                >
                  <v-list-item
                    v-for="item in tcPanel.items"
                    :key="item.title"
                    class="px-0 py-0"
                  >
                    <v-list-item-avatar
                      :size="24"
                      color="#fff"
                      class="my-0 me-4 shadow"
                      :class="`bg-gradient-default`"
                    >
                      <v-icon size="9" class="text-white">{{
                        item.icon
                      }}</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content class="text-left">
                      <v-list-item-title
                        v-text="item.title"
                        class="text-body-1 ls-0 text-muted"
                      ></v-list-item-title>
                      <v-list-item-subtitle class="text-pre-wrap">
                        {{ item.content }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-main>
</template>
<script>


export default {
  name: "privacy-policy",
  data() {
    return {

    };
  },
  computed:{
    tcPanel(){
      return {
        items:[
          {
            icon: "fa fa-minus",
            title: "",
            content:
                this.$translator.trans("The Path Forward places a high priority on protecting your privacy. This privacy policy was created in order to demonstrate our firm commitment to the privacy of our donors, persons in need and website users. This policy explains what types of information is collected by the The Path Forward website, www.thepathforward.help, and how this information is used."),
          },
          {
            icon: "fa fa-minus",
            title: this.$translator.trans("What Personally Identifiable Information is Collected"),
            content:
                this.$translator.trans('The Path Forward persons in need, and volunteers that fill out a form at thepathforward.help and individuals that sign up to receive The Path Forward e-communications voluntarily provide us with contact information (such as name, e-mail address, phone number and other data). We may use this information for specific, limited purposes. You may always "opt out," either now or at any time in the future, if you do not wish to receive our messages or communications.') +'\n' +
                this.$translator.trans("Any information that you may chose to share, in addition to what it is requested while creating your account, it is solely your responsibility. We do not recommend sharing any confidential information ; The Path Forward is not responsible to protect any information that was not requested upon creating the account."),
          },
          {
            icon: "fa fa-minus",
            title: this.$translator.trans("IP addresses"),
            content:
                this.$translator.trans("The Path Forward uses your IP address to help diagnose problems with our server, to administer thepathforward.help, and for statistical metrics used to track website visitor traffic."),
          },
          {
            icon: "fa fa-minus",
            title: this.$translator.trans("Cookies"),
            content:
                this.$translator.trans('www.thepathforward.help uses "cookie" messages to automatically help provide better services. They remind us who you are and your preferences for our website based on what you\'ve done and told us before. The "cookie" is placed in your computer and is read when you come back to our website. Cookies let us take you to the information and features you\'re particularly interested in. They also let us track your usage of pandemicoflove.com so we know which parts of our sites are most popular. You can reject cookies or cancel them by instructing your Web browser accordingly.'),
          },
          {
            icon: "fa fa-minus",
            title: this.$translator.trans("How Your Information May Be Used"),
            content:
                this.$translator.trans("We use your personal information to provide you with personalized service; to send e-mail alerts to you; to answer your requests; to process your forms and applications; etc. You may choose to opt out at any time, which will cease all communications from us. We may also use your information to track visitors of to our website. This lets us see which of our features are the most popular so we can better serve our users' needs. It also lets us provide aggregate data about our traffic (not identifying you personally, but showing how many visitors used which features, for example) to outside parties."),
          },
          {
            icon: "fa fa-minus",
            title: this.$translator.trans("Email Privacy"),
            content:
                this.$translator.trans("The Path Forward does not provide, sell, or rent email addresses to anyone outside the organization."),
          },
          {
            icon: "fa fa-minus",
            title: this.$translator.trans("External Links"),
            content:
                this.$translator.trans("www.thepathforward.help includes links to external websites. Some of these links do not fall under the www.thepathforward.help domain, and The Path Forward is not responsible for the privacy practices or the content of external websites. Your use of any linked website is solely at your own risk."),
          },
          {
            icon: "fa fa-minus",
            title: this.$translator.trans("Modifications"),
            content:
                this.$translator.trans("We may amend this privacy policy from time to time; please review it periodically. We maintain the option to modify this privacy at any time by electronic notice posted on our website. Your continued use of our website after the date that such notices are posted will be deemed to be your agreement to the changed terms."),
          },
        ]
      }
    }
  }
};
</script>
